.mb-3 {
  margin-top: 20px;
  margin-left: 50px;
  color: black;
  width: 80%;
  height: 40px;
}

.form-control1 {
  width: 300px;
  height: 30px;
  text-align: center;
  background-color: white;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 20px;
  margin-left: 100px;
}

.form-control2 {
  width: 300px;
  height: 30px;
  text-align: center;
  background-color: white;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 20px;
  margin-left: 100px;
}

.form-control3 {
  width: 300px;
  height: 30px;
  text-align: center;
  background-color: white;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 20px;
  margin-left: 100px;
}

.form-control4 {
  width: 300px;
  height: 30px;
  text-align: center;
  background-color: white;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 20px;
  margin-left: 100px;
}

.btn-primary {
  background-color: #2557a3;
  border: 1px solid #2557a3;
  color: #fff;
  width: 300px;
  font-size: 20px;
  margin-top: 30px;
  padding: 16px;
  border-radius: 8px;
  height: 60px;
  margin-left: 150px;
  cursor: pointer;
}
