.primary {
  color: #1976d2;
}

.main {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-image: url("../images/blur.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
}

.left_area {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_area {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login {
  display: flex;
  gap: 20px;
  align-items: center;
  background-size: cover;
}

.id-password-form {
  width: 400px;
  background-color: white;
  border: 1px solid white;
  border-radius: 20px;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.id-password-form h1 {
  margin: 29px 0;
  width: 100%;
  font-weight: bold;
  margin-bottom: 1rem;
}

.id-password-form h2 {
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  color: #455a64;
  margin: 0.25rem;
}

.id-password-form p {
  width: 100%;
  font-weight: 400;
  font-size: 0.9rem;
}

.id-password-form form {
  width: 100%;
  padding: 1rem;
}

.id-password-form form .input_title,
.id-password-form form .input_title2 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.id-password-form form .id-input,
.id-password-form form .password-input {
  width: 100%;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
}

.id-password-form form .submit-button {
  background-color: #2557a3;
  border: 1px solid #2557a3;
  color: #fff;
  width: 100%;
  font-size: 1rem;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
}

.LoginImage {
  width: 800px;
  height: 800px;
  max-width: 800px;
  max-height: 800px;
  border: 1px solid black;
  border-radius: 20px;
  background-image: url("../images/robot.jpg");
  background-size: cover;
  background-position: center;
}

/* 모바일(720px 브라우저)에서 동작하는 추가 css */
@media (max-width: 720px) {
  /* 입력 창이 센터에 위치하는게 아니라 위에서 아래로 흐르도록 */
  .main {
    flex-direction: column;
  }

  .id-password-form h1 {
    font-size: 1.5rem;
  }

  .id-password-form h2 {
    font-size: 1rem;
  }

  /* 오른쪽 yesbot 이미지가 안보이도록 설정 */
  .right_area {
    display: none;
  }

  /* 입력 창의 크기가 최소 full로 동작하도록 */
  .id-password-form {
    width: 100%;
    padding: 1rem 2rem;
  }

  .id-password-form form {
    width: 100%;
    padding: 0.25rem;
  }

  .id-password-form form .submit-button {
    margin-top: 1rem;
  }
}
