/* 
색상은 아래에서 선택할 것
https://mui.com/material-ui/customization/color/
 */

.chat-container {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.chat-container .chat-box {
  margin: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/********************************************** 메시지 **********************************************/
.chat-container .chat-user-message {
  list-style-type: none;
  padding: 1rem;
  background-color: #fff176;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  width: fit-content;
  margin-left: auto;
}

.chat-container .chat-bot-message {
  list-style-type: none;
  background-color: #90caf9;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  width: fit-content;
}

.chat-container .chat-download-message {
  padding: 0.5rem 0.75rem;
  background-color: #b0bec5;
  border: 1px solid #78909c;
  border-radius: 0.5rem;
  list-style-type: none;
  width: fit-content;
  font-size: 0.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.chat-container .chat-download-message .download-icon {
  margin-right: 0.25rem;
  font-size: 1rem;
}

.chat-container .is-typing {
  list-style-type: none;
  background-color: #b0bec5;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  display: flex;
  width: fit-content;
}

.chat-container .is-typing .spin {
  margin-right: 0.5rem;
}

/********************************************** 입력창 **********************************************/
.chat-container .input-container .input-box {
  position: relative;
}

.chat-container .input-container .input-box .chat-input {
  width: 100%;
  padding: 0.75rem 1.25rem;
  padding-right: 3rem;
  border: none;
  border-top: 1px solid #bdbdbd;
  margin-top: -1px;
  font-size: 1rem;
  box-sizing: border-box;
}

.chat-container .input-container .input-box .chat-input:focus {
  outline: none;
}

.chat-container .input-container .input-box .send-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3rem;
  right: 0.25rem;
  border: none;
  background: none;
  font-size: 1.25rem;
  color: #1565c0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 모바일(1024px 브라우저)에서 동작하는 추가 css */
@media (max-width: 1024px) {
  .chat-container .input-container .input-box .send-button {
    right: 0.5rem;
  }
}
