/* 
색상은 아래에서 선택할 것
https://mui.com/material-ui/customization/color/
 */

.gpt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/********************************************** 챗봇 메시지(왼쪽) **********************************************/
.gpt .gpt_left {
  width: 100%;
  max-width: 720px;
  height: 90vh;
  min-height: 90vh;
  border: 1px solid #bdbdbd;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: overlay;
  overflow-x: hidden;
}

.gpt .gpt_left .gpt-nav {
  background: #1976d2;
  color: white;
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gpt .gpt_left .gpt-nav .YESBOT {
  width: 1.75rem;
  height: 1.75rem;
}

/********************************************** 가이드(오른쪽) **********************************************/
.gpt .gpt_right {
  width: 100%;
  min-width: 500px;
  max-width: 500px;
  border: 1px solid #bdbdbd;
  border-radius: 1rem;
  margin: 2rem;
}

.gpt .gpt_right .guide-nav {
  background: #009688;
  display: flex;
  color: white;
  padding: 20px;
  font-weight: 600;
  font-size: 20px;
  border-radius: 1rem 1rem 0 0;
}

.gpt .gpt_right .guide-nav .YESBOT {
  width: 1.75rem;
  height: 1.75rem;
}

.gpt .gpt_right .container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.gpt .gpt_right .container .box {
  padding: 0.25rem;
}

/* 모바일(1024px 브라우저)에서 동작하는 추가 css */
@media (max-width: 1024px) {
  .gpt {
    flex-direction: column;
  }

  .gpt .gpt_left {
    margin: 0;
    border: 1px solid #bdbdbd;
    height: 100vh;
    min-height: 100vh;
    overflow: scroll;
  }

  /* 오른쪽 설명이 안보이도록 설정 */
  .gpt_right {
    display: none;
  }
}
